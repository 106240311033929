import * as React from 'react'
import withHydrationOnDemand from 'react-hydration-on-demand'
import loadable from '@loadable/component'
import dynamic from 'next/dynamic'
import ResizeObserver from 'resize-observer-polyfill'

import {
  useFormattableI18nProperty,
  useHeaderHeight,
  useI18n,
  useRequestConfig,
  useSessionSettings,
  useSiteConfig,
  useTheme,
} from '@thg-commerce/enterprise-core'
import {
  FacetInput,
  ProductSort,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  ProductCategories,
  ProductCategoryItemProps,
} from '@thg-commerce/enterprise-widget-product-categories'
import { SafeHtml } from '@thg-commerce/gravity-elements'
import { OptionsType } from '@thg-commerce/gravity-elements/CustomDropdown/CustomDropdown'
import { ProductListGridSelector } from '@thg-commerce/gravity-elements/ProductListGridSelector'
import { ReadMore } from '@thg-commerce/gravity-patterns'
import { useScrollLock } from '@thg-commerce/gravity-system'

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
import { ProductListRenderer } from '@thg-commerce/enterprise-components/ProductListRenderer/ProductListRenderer'
import { QubitPersonalisedContentRenderer } from '@thg-commerce/enterprise-components/Qubit/QubitPersonalisedContent/QubitPersonalisedContentRenderer'
import { useQubitPlacementImpression } from '@thg-commerce/enterprise-components/Qubit/useQubitPlacementImpression'
import { SponsoredAdsType } from '@thg-commerce/enterprise-components/SponsoredAds/queryBuilders'
import {
  ColumnTypeDataLayerGA4,
  productEventsCategory,
  pushToEventGA4,
  SortByDataLayerGA4,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import { DataLayerProducts } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Page/ProductListProducts'
import { ZIndexLevel } from '@thg-commerce/enterprise-theme'
import { TextStyling } from '@thg-commerce/gravity-theme'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'

import { facetsCallbacks } from './dataTracking'
import {
  FacetType,
  HorizontalFacetSkeleton,
  HorizontalFacetsProps,
  VerticalFacetSkeleton,
  VerticalFacetsProps,
} from './Facets'
import { ProductItem as ProductItemComponent } from './ProductItem'
import {
  AccessibilityButton,
  Container,
  DescriptionStyledGridItem,
  FacetProductWrapper,
  HiddenTitle,
  HideOnMobile,
  ProductListFooterGrid,
  ProductListGrid,
  ProductListGridItem,
  ProductListIntroGrid,
  ProductListItem,
  QubitListItem,
  RefineButton,
  RefineIconTextWrapper,
  RefineText,
  StickyFilterGrid,
  StyledDropdown,
  StyledGridItem,
  StyledPagination,
  StyledSafeHTML,
  Title,
  TotalResults,
  TotalResultsStyledGridItem,
  VerticalFacetsWrapper,
} from './styles'

export enum Focus {
  VERTICAL_FACETS_TITLE = 'VERTICAL_FACETS_TITLE',
  PRODUCT_LIST_TITLE = 'PRODUCT_LIST_TITLE',
}

export enum PageOptionsParams {
  FACET_FILTERS = 'facetFilters',
  PAGE_NUMBER = 'pageNumber',
  SORT_ORDER = 'sortOrder',
  IFP = 'IFP',
}

export interface PageOptions {
  sort?: ProductSort
  facets?: FacetInput[]
  pageNumber?: number
  searchQuery?: string
}

export interface ProductListPageProps {
  title?: string
  description?: string
  seoDescription?: string
  categories?: ProductCategoryItemProps[]
  categoriesTitle?: string | null
  facets: FacetType[]
  products: any
  onInputChange: (newInput: PageOptions) => void
  pageOptions: Required<PageOptions>
  sortTypes: {
    type: ProductSort
    displayText: string
  }[]
  itemsPerPage: number
  totalItems: number
  loading?: boolean
  changeType?: ChangeType
  dataLayerProducts?: DataLayerProducts
  setSingleColumn?: (item: string, value: string) => void
  facetsInTitle?: string
  sponsoredAdsType: SponsoredAdsType
  path?: string
  triggerCallbackOnSave?: boolean
  noSearchResultsFound?: {
    title?: TextStyling
    subtitle?: {
      text: TextStyling
      paddingBottom: number
    }
  }
  dataLayerMap: {
    [key: string]: {
      [key: string]: {
        propertyKey: string
        label: string
      }
    }
  }
}

export enum ChangeType {
  NONE,
  FACET,
  SORT,
  PAGE,
}

const MobileFacets = dynamic<any>(() =>
  import('./Facets/MobileFacets/MobileFacets').then((mod) => mod.MobileFacets),
)
const VerticalFacets = dynamic<VerticalFacetsProps>(() =>
  import('./Facets/VerticalFacets/VerticalFacets').then(
    (mod) => mod.VerticalFacets,
  ),
)
const HorizontalFacets = dynamic<HorizontalFacetsProps>(() =>
  import('./Facets/HorizontalFacets/HorizontalFacets').then(
    (mod) => mod.HorizontalFacets,
  ),
)

const RefineIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/RefineIcon'),
)

const ProductItem = withHydrationOnDemand({ on: ['visible'] })(
  ProductItemComponent,
)
enum GridSelectorType {
  singleColumn = 1,
  productGrid = 2,
}
export enum GridView {
  SingleColumn = 'singleColumn',
  ProductGrid = 'productGrid',
}

const ProductGridType = 'productViewColumn'

const getStyledGridMarginTopSpacing = (
  marginTop?: number,
  alternateResultsPosition?: boolean,
): number => {
  if (alternateResultsPosition) {
    return 0
  }
  return marginTop || marginTop === 0 ? marginTop : 5
}

const getTitleColSpan = (
  fallbackValue: number[] | number,
  width?: string,
): number[] | number => {
  if (width === '100%') {
    return 12
  }
  return fallbackValue
}

const getStickyFilterMarginTop = (
  fallbackValue: number,
  categories?: ProductCategoryItemProps[],
  enableStickyFacetsOnMobile?: boolean,
): number | undefined => {
  if (!categories && !enableStickyFacetsOnMobile) {
    return fallbackValue
  }
  return undefined
}

export const ProductListPage = (props: ProductListPageProps) => {
  const theme = useTheme()
  const {
    hasDiscountSortOption,
    hasNewestSortOption,
    hideProductListTotalResults,
    hideProductListReadMore,
    hideProductListFooterReadMore,
    enableHorizontalFacets,
    hideProductListReviewRating,
    hideProductListProductBlockButton,
    productListReviewRatingThreshold,
    showMarketedSpecialOfferIcon,
    hideProductListImageRollover,
    showProductListProductBlockIcons,
    enableClickAndCollect,
    showPdpLinkWhenOutOfStock,
    previewQuickbuy,
    showProductBlockBrandTitle,
    showProductBlockBrandLogo,
    hideProductListSwatch,
    enableStickyFacetsOnMobile,
    enableStickyFacetsOnDesktop,
    useGA4EnhancedEcom,
    enableWishlists,
    useExternalIdentifier,
    useExternalIdentifierInSchema,
    openAllVerticalFacets,
    hasFourProductsPerRowDesktopPLP,
    hideListPageProductActions,
    hideListPageProductPrice,
    enableProductListGridSelector,
    alternateResultsPosition,
    hasImagesCarouselOnMobilePLP,
    qubit,
    enablePapOverlay,
    alternateProductKeysForCategories,
    papProductItemHighlightedTitleList,
    enableAlternateMobileFacet,
    hideCarouselButtonOnMobile,
    enableScrollableList,
  } = useSiteConfig()

  const requestConfig = useRequestConfig()

  const sessionSettings = useSessionSettings()

  const [showMobileFacets, setShowMobileFacets] = React.useState(false)

  const [pageOptions, setPageOptions] = React.useState<PageOptions>(
    props.pageOptions,
  )
  const [refreshFacets, setRefreshFacets] = React.useState(false)

  const [productViewColumn, setProductViewColumn] = React.useState<string>(
    GridView.ProductGrid,
  )

  const [facetChange, setFacetChange] = React.useState<boolean>(false)
  const i18n = useI18n()

  const sessionStorage = typeof window !== 'undefined' && window.sessionStorage

  React.useEffect(() => {
    if (
      enableProductListGridSelector &&
      sessionStorage &&
      sessionStorage.getItem(ProductGridType)
    ) {
      setProductViewColumn(
        sessionStorage.getItem(ProductGridType) === GridView.SingleColumn
          ? GridView.SingleColumn
          : GridView.ProductGrid,
      )
    }
  }, [enableProductListGridSelector, sessionStorage])

  const [focusedElement, setFocusedElement] = React.useState<Focus | null>(null)

  const scrollAnchorRef = React.useRef<HTMLDivElement>(null)
  const stickyFacetsRef = React.useRef<HTMLDivElement>(null)
  const productListPageTitleRef = React.useRef<HTMLHeadingElement>(null)
  const verticalFacetsTitleRef = React.useRef<HTMLHeadingElement>(null)

  const emitImpressionEvent = useQubitPlacementImpression()

  const setScrollLock = useScrollLock()

  const headerHeight = useHeaderHeight()

  React.useEffect(() => {
    const sessionOneProductPerRow =
      sessionStorage && sessionStorage.getItem(ProductGridType)
    if (enableProductListGridSelector && useGA4EnhancedEcom) {
      pushToEventGA4<ColumnTypeDataLayerGA4>({
        event: 'column_type_selected',
        col_type: 'Default',
        col_number: sessionOneProductPerRow
          ? GridSelectorType[sessionOneProductPerRow]
          : GridSelectorType[productViewColumn],
      })
    }
  }, [
    enableProductListGridSelector,
    productViewColumn,
    sessionStorage,
    useGA4EnhancedEcom,
  ])

  const scrollAnchorOffsetTop = React.useRef(0)

  React.useEffect(() => {
    let resizeObserver: ResizeObserver | undefined
    if (scrollAnchorRef?.current) {
      resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        const { offsetTop } = entries[0]?.target as HTMLDivElement
        scrollAnchorOffsetTop.current = offsetTop
      })
      resizeObserver.observe(scrollAnchorRef.current)
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [scrollAnchorRef, scrollAnchorOffsetTop])

  const scrollToTop = () => {
    const headerHeightAdjustment = enableStickyFacetsOnDesktop
      ? stickyFacetsRef?.current?.offsetHeight || 0
      : -40 + headerHeight

    const noMarginAdjustment = alternateResultsPosition ? 48 : 0

    const verticalFacetsAdjustment = enableHorizontalFacets
      ? noMarginAdjustment
      : 8
    const targetScrollTop =
      scrollAnchorOffsetTop.current -
      headerHeightAdjustment -
      verticalFacetsAdjustment

    window.scrollTo({
      top: targetScrollTop,
      behavior: 'smooth',
    })
  }

  const handleGoToProductSection = () => {
    if (enableStickyFacetsOnMobile) {
      return
    }
    setFocusedElement(Focus.PRODUCT_LIST_TITLE)
    productListPageTitleRef?.current?.focus()
  }

  const handleGoToRefineSection = () => {
    setFocusedElement(Focus.VERTICAL_FACETS_TITLE)
    verticalFacetsTitleRef?.current?.focus()
  }

  React.useEffect(() => {
    showMobileFacets
      ? setScrollLock(true, document, window)
      : setScrollLock(false, document, window)
  }, [showMobileFacets, setScrollLock])

  React.useEffect(() => {
    if (!useGA4EnhancedEcom) {
      return
    }

    const data: {
      item_name?: string
      item_id?: string | number
      price?: string | number
      item_brand?: string | { name: string }
      item_list_name?: string
      index?: number
      category?: {
        key: string
        value: {
          stringListValue: string[]
        }
      }[]
      item_variant?: string
      quantity?: number
    }[] = []

    props.dataLayerProducts?.forEach((product) => {
      const productContentList = product?.category || []

      data.push({
        item_name: product?.name,
        item_id: product?.id,
        price: product?.price,
        item_brand: product?.brand,
        item_list_name: product?.list_name,
        index: product?.list_position,
        item_variant: product?.item_variant,
        quantity: 1,
        ...productEventsCategory(
          productContentList,
          alternateProductKeysForCategories,
        ),
      })
    })

    pushToEventGA4({
      event: 'ecom_event',
      event_name: 'view_item_list',
      ecommerce: {
        currencyCode: sessionSettings?.currency,
        items: data,
      },
    })
  }, [
    props.dataLayerProducts,
    sessionSettings?.currency,
    useGA4EnhancedEcom,
    alternateProductKeysForCategories,
  ])

  React.useEffect(() => {
    if (!props.triggerCallbackOnSave) {
      return
    }

    if ((!showMobileFacets && facetChange) || refreshFacets) {
      props.onInputChange(pageOptions)
      setRefreshFacets(false)
      setFacetChange(false)
    }
  }, [
    props.triggerCallbackOnSave,
    refreshFacets,
    showMobileFacets,
    facetChange,
    pageOptions,
    props,
  ])

  const i18nText = {
    readMore: i18n('general.readmore.text'),
    readLess: i18n('general.readless.text'),
    sortBy: i18n('product.list.sort.arialabel'),
    productsHiddenTitle: i18n('general.accessibility.products.label'),
    rrpText: i18n('product.price.rrp.text'),
    saveUpTo: i18n('product.price.saving.up.to.text'),
    fromText: i18n('product.price.from.text'),
    offLabel: i18n('general.off.text'),
    paginationi18nText: {
      next: i18n('general.pagination.next'),
      previous: i18n('general.pagination.previous.shorthand'),
      ofTotal: i18n('general.pagination.oftotal'),
      page: i18n('general.pagination.page'),
      dropdownLabel: i18n('general.pagination.dropdownlabel'),
      currentlyOnText: i18n('general.pagination.currentlyontext'),
      ariaLabel: i18n('general.pagination.arialabel'),
      totalResultsText: i18n('general.pagination.totalresults.text').replace(
        '%s',
        props.totalItems.toString(),
      ),
    },
  }

  const i18nFacetsText = {
    back: i18n('general.back.text'),
    clear: i18n('product.list.facets.clear.text'),
    save: i18n('product.list.facets.submit.text'),
    close: i18n('product.list.facets.close.text'),
    refine: i18n('product.list.facets.refine.text'),
    errorMessage: i18n('general.error.generic.text'),
    clearAll: i18n('product.list.facets.clearall.text'),
    editRefine: i18n('product.list.facets.editrefine.text'),
    sliderHeader: i18n('product.list.filter.slider.header'),
    filterText: i18n('product.list.facets.expandfilter.text'),
    searchBrandLabel: i18n('product.list.facets.searchbrand.label'),
    goToRefineSection: i18n('product.accessibility.go.to.refine.section'),
    minimumLabel: i18n('product.list.filter.price.minimum.price.ariaLabel'),
    maximumLabel: i18n('product.list.filter.price.maximum.price.ariaLabel'),
    goToProductSection: i18n('product.accessibility.go.to.product.section'),
    searchBrandPlaceholder: i18n('product.list.facets.searchbrand.placeholder'),
  }

  const descriptionColSpan = enableHorizontalFacets
    ? theme.widget.productList.readMoreSection.colSpan?.horizontal || [
        12,
        10,
        8,
        8,
      ]
    : theme.widget.productList.readMoreSection.colSpan?.vertical || [
        12,
        10,
        9,
        8,
      ]

  const PLP_IMAGES_EAGERLY_LOADED = 8
  const facetSchema = props.dataLayerMap.facet || {}
  const buyAriaLabelFormatted = useFormattableI18nProperty(
    'general.productbuynow.label',
  )

  const addToWishlistTextDynamic = useFormattableI18nProperty(
    `product.addtowishlistbutton.addtowishlist.text.dynamic`,
  )

  const productBlocks = React.useMemo(() => {
    const displayQubitPersonalisedContent =
      qubit?.enabled && !!qubit.placements.personalisedContentId

    const getQubitColSpan = (
      itemIndex: number,
      qubitRow: number,
      productsPerRow: number,
    ) => {
      if (!displayQubitPersonalisedContent) {
        return undefined
      }
      const qubitIndex = (qubitRow - 1) * productsPerRow
      return itemIndex === qubitIndex ? productsPerRow + 1 : undefined
    }

    return props.products.map((product, index) => {
      const dynamicDelivery = (weightGroups) => {
        return i18n(`product.item.fulfilment.${weightGroups}`)
      }

      const productI18nText = {
        buyText: i18n('general.productbuynow.text'),
        personalisedBuyText: i18n('personalisation.productbuynow.text'),
        quickBuyText: i18n('general.product.quickbuy.text'),
        preorderNowText: i18n('listitem.button.preordernow.text'),
        soldOutText: i18n('general.productsoldout.text'),
        reviewsStarsLabel: i18n('reviews.stars.label'),
        reviewsLabel: i18n('reviews.reviews.label'),
        closeI18nText: {
          closeAriaLabel: i18n('general.modal.close.button.arialabel'),
          closeLabel: i18n('general.modal.close.button.label'),
        },
        freeGiftMessage: i18n('product.marketedspecialoffer.freegift.text'),
        swatchUnavailableText: i18n(
          'product.productoptions.swatch.tooltip.unavailable.text',
        ),
        swatchCloseButtonText: i18n(
          'product.productoptions.swatch.tooltip.close.text',
        ),
        swatchMoreText: i18n('product.productblock.colourswatch.more.text'),
        swatchMoreTextAlt: i18n(
          'product.productblock.colourswatch.more.alt.text',
        ),
        fulfilmentMethodsText: {
          clickAndCollect: {
            isAvailable: i18n('product.list.clickandcollect.text'),
            isNotAvailable: i18n('product.clickandcollect.unavailable.text'),
          },
          homeDelivery: {
            isAvailable: i18n('product.list.delivery.available'),
            isNotAvailable: i18n('product.delivery.home.unavailable.text'),
            isOutOfStock: i18n('clickandcollect.store.availability.outofstock'),
            nextDayDelivery: i18n('product.list.item.nextdaydelivery'),
            oneManDelivery: i18n(
              'product.list.fulfilment.1man.nextdaydeliveryavailable',
            ),
            outOfGaugeDelivery: i18n(
              'product.list.fulfilment.outofgauge.nameddaydeliveryavailable',
            ),
          },
          storeDelivery: {
            isAvailable: i18n('product.delivery.store.available.text'),
          },
          orderInStore: {
            isAvailable: i18n(
              'product.item.fulfilment.orderinstore.available.text',
            ),
          },
        },
      }

      const wishlistI18nText = {
        addToWishlistTextDynamic,
        addToWishlistText: i18n(
          'product.addtowishlistbutton.addtowishlist.text',
        ),
        savedToWishlistText: i18n(
          'product.addtowishlistbutton.savedtowishlist.text',
        ),
        wishlistTooltip: {
          closeButtonText: i18n(
            'product.addtowishlist.tooltip.button.close.text',
          ),
        },
        wishlistTooltipContent: {
          loginSignupText: i18n(
            'product.addtowishlist.tooltip.loginsignup.text',
          ),
          toUseWishlistText: i18n('product.addtowishlist.tooltip.text'),
        },
      }

      const qubitColSpanOnDesktop = getQubitColSpan(
        index,
        qubit?.personalisedContentRowOnDesktop ?? 3,
        hasFourProductsPerRowDesktopPLP || enableHorizontalFacets ? 4 : 3,
      )
      const qubitColSpanOnMobile = getQubitColSpan(
        index,
        qubit?.personalisedContentRowOnMobile ?? 4,
        productViewColumn === GridView.SingleColumn ? 1 : 2,
      )

      return (
        <React.Fragment>
          {(qubitColSpanOnDesktop || qubitColSpanOnMobile) && (
            <QubitListItem
              data-testid="qubit-personalised-content-item"
              colSpanOnDesktop={qubitColSpanOnDesktop}
              colSpanOnMobile={qubitColSpanOnMobile}
            >
              <QubitPersonalisedContentRenderer
                emitImpressionEvent={emitImpressionEvent}
              />
            </QubitListItem>
          )}
          <ProductListItem
            oneProductPerRow={productViewColumn === GridView.SingleColumn}
            data-testid="product-item"
            key={product.sku}
          >
            <ProductItem
              wrapperProps={{
                style: { height: '100%' },
              }}
              mobileSingleColumn={productViewColumn === GridView.SingleColumn}
              attributesInsetSpacing={
                theme.widget.productList.attributesInsetSpacing
              }
              emitImpressionEvent={emitImpressionEvent}
              externalIdentifier={product.externalIdentifier}
              style={{ width: '100%' }}
              key={index}
              index={index}
              hoverPicture={product.hoverPicture}
              quickBuyFeatureEnabled={previewQuickbuy}
              productI18nText={{
                ...productI18nText,
                buyAriaLabel: buyAriaLabelFormatted,
                fulfilmentMethodsText: {
                  ...productI18nText.fulfilmentMethodsText,
                  homeDelivery: {
                    ...productI18nText.fulfilmentMethodsText.homeDelivery,
                    datedDelivery: i18n(
                      'basket.item.fulfilment.leadtime',
                      product.leadTime?.toString(),
                    ),
                    dynamicDelivery: dynamicDelivery(
                      product.weightGroups?.[0]?.toLowerCase(),
                    ),
                  },
                },
              }}
              wishlistI18nText={wishlistI18nText}
              lazy={index > PLP_IMAGES_EAGERLY_LOADED - 1}
              pricePerUnit={product.pricePerUnit}
              siteConfig={{
                hideProductListReviewRating,
                hideProductListProductBlockButton,
                productListReviewRatingThreshold,
                showMarketedSpecialOfferIcon,
                hideProductListImageRollover,
                showProductListProductBlockIcons,
                enableClickAndCollect,
                showPdpLinkWhenOutOfStock,
                enableHorizontalFacets,
                showProductBlockBrandTitle,
                showProductBlockBrandLogo,
                hideProductListSwatch,
                hasImagesCarouselOnMobilePLP,
                useGA4EnhancedEcom,
                enableWishlists,
                useExternalIdentifier,
                useExternalIdentifierInSchema,
                enablePapOverlay,
                hideListPageProductActions,
                hideListPageProductPrice,
                papProductItemHighlightedTitleList,
              }}
              {...{
                ...product,
                price: {
                  ...product.price,
                  i18nText: { offLabel: i18nText.offLabel },
                  price: product.price.price,
                  rrp: product.price.rrp,
                  rrpText: i18nText.rrpText,
                  saveUpToText: i18nText.saveUpTo,
                  savings: theme.patterns.productBlock.price.savings,
                  savingsPercentage:
                    theme.patterns.productBlock.price.savingsPercentage,
                  fromText:
                    product.cheapestVariant?.price?.price.amount !==
                    product.mostExpensiveVariant?.price?.price?.amount
                      ? i18nText.fromText
                      : undefined,
                },
                icons: product.icons,
                sponsoredLabel: product.sponsoredLabel,
              }}
              content={product.content}
              wishlistButtonStyle={
                theme.widget.productList.wishlistButton?.style
              }
              qubitBadgeStyle={theme.widget.productList.qubitBadge}
              listPageTitle={props.title}
              papBadgeStyle={theme.widget.productList.productItemOverlay}
              selectedOptions={product.selectedOptions}
              personalisationFields={
                product?.personalisationFields ||
                product?.defaultVariant?.personalisationFields
              }
              dataLayerBrand={props.dataLayerProducts?.[index]?.brand}
            />
          </ProductListItem>
        </React.Fragment>
      )
    })
  }, [
    props.dataLayerProducts,
    props.products,
    productViewColumn,
    emitImpressionEvent,
    enableHorizontalFacets,
    enablePapOverlay,
    enableWishlists,
    enableClickAndCollect,
    hasFourProductsPerRowDesktopPLP,
    hasImagesCarouselOnMobilePLP,
    hideProductListImageRollover,
    hideProductListProductBlockButton,
    hideProductListReviewRating,
    hideProductListSwatch,
    i18n,
    i18nText.fromText,
    i18nText.rrpText,
    i18nText.saveUpTo,
    i18nText.offLabel,
    papProductItemHighlightedTitleList,
    previewQuickbuy,
    productListReviewRatingThreshold,
    props.title,
    qubit?.enabled,
    qubit?.personalisedContentRowOnDesktop,
    qubit?.personalisedContentRowOnMobile,
    qubit?.placements.personalisedContentId,
    showMarketedSpecialOfferIcon,
    showPdpLinkWhenOutOfStock,
    showProductBlockBrandLogo,
    showProductBlockBrandTitle,
    showProductListProductBlockIcons,
    theme.widget.productList.attributesInsetSpacing,
    theme.widget.productList.productItemOverlay,
    theme.widget.productList.qubitBadge,
    theme.widget.productList.wishlistButton?.style,
    theme.patterns.productBlock.price.savings,
    theme.patterns.productBlock.price.savingsPercentage,
    useExternalIdentifier,
    useExternalIdentifierInSchema,
    useGA4EnhancedEcom,
    buyAriaLabelFormatted,
    addToWishlistTextDynamic,
  ])

  const dropdownOptions: OptionsType[] = React.useMemo(
    () =>
      props.sortTypes
        .filter(
          (option) =>
            (option.type !== ProductSort.NewestToOldest ||
              hasNewestSortOption) &&
            (option.type !== ProductSort.DiscountPercentageHighToLow ||
              hasDiscountSortOption),
        )
        .map((option) => {
          return {
            key: option.type,
            value: option.displayText,
            displayText: option.displayText,
          }
        }),

    [props.sortTypes, hasDiscountSortOption, hasNewestSortOption],
  )

  const selectedSort = props.sortTypes.find(
    (sort) => sort.type === props.pageOptions.sort,
  )?.type

  const showPagination = props.totalItems > props.itemsPerPage

  const onMobileFacetChange = (newInput: PageOptions) => {
    setFacetChange(true)

    if (props.triggerCallbackOnSave) {
      setPageOptions(newInput)
      return
    }

    props.onInputChange(newInput)
    setShowMobileFacets(false)
  }

  const scrollOnChange = (newInput: PageOptions) => {
    scrollToTop()

    if (props.triggerCallbackOnSave) {
      setPageOptions(newInput)
      setFacetChange(true)
      return
    }

    props.onInputChange(newInput)
  }

  const handleFacetsRefresh = (refresh: boolean) => {
    if (!props.triggerCallbackOnSave) {
      return
    }

    setRefreshFacets(refresh)
  }

  const getSelectedFacets = () => {
    if (props.triggerCallbackOnSave) {
      return pageOptions.facets
    }

    return props.pageOptions.facets
  }
  const getMarginTopValue = () => {
    if (!props.categories && !enableStickyFacetsOnMobile) {
      return (
        theme.widget.productList?.filterGrid?.filterGridMarginTopSpacing || 4
      )
    }
    return undefined
  }

  return (
    <Container
      id="ProductList"
      aria-labelledby="responsive-product-list-title"
      headerHeight={headerHeight}
      containerSpacing={theme.widget.productList.containerSpacing}
    >
      <FacetProductWrapper>
        {showMobileFacets && (
          <MobileFacets
            facets={props.facets}
            selectedFacets={getSelectedFacets()}
            onInputChange={onMobileFacetChange}
            setShowMobileFacets={(setMobileFacet) => {
              if (showMobileFacets && !setMobileFacet) {
                scrollToTop()
              }
              setShowMobileFacets(setMobileFacet)
              handleGoToProductSection()
            }}
            showMobileFacets={showMobileFacets}
            i18nText={i18nFacetsText}
            setRefreshFacets={handleFacetsRefresh}
            facetSchema={facetSchema}
            enableAlternateMobileFacet={enableAlternateMobileFacet}
          />
        )}
        {!enableHorizontalFacets && (
          <VerticalFacetsWrapper>
            {props.loading &&
            (props.changeType === ChangeType.FACET ||
              props.changeType === ChangeType.NONE) ? (
              <VerticalFacetSkeleton
                refineText={i18nFacetsText.refine}
                sections={Array(3).fill({
                  itemCount: 5,
                })}
              />
            ) : (
              <VerticalFacets
                facets={props.facets}
                marginTop={
                  theme.widget.productList.verticalFacets?.facet.margin.top
                }
                selectedFacets={props.pageOptions.facets}
                onInputChange={scrollOnChange}
                i18nText={i18nFacetsText}
                onAccessibilityButtonClick={handleGoToProductSection}
                focusedElement={focusedElement}
                onAccessibilityButtonBlur={() => setFocusedElement(null)}
                verticalFacetsTitleRef={verticalFacetsTitleRef}
                openAllFacets={openAllVerticalFacets}
                facetSchema={facetSchema}
              />
            )}
          </VerticalFacetsWrapper>
        )}
        <div style={{ width: '100%' }}>
          <ProductListIntroGrid
            data-testid="products-intro-container"
            containerSpacing={theme.widget.productList.introSectionSpacing}
            rowGap={theme.widget.productList.productListIntroSectionSpacing}
            marginBottom={
              theme.widget.productList.productListIntroSectionMarginBottom
            }
          >
            <StyledGridItem
              colSpan={getTitleColSpan(
                [12, 10, 9, 8],
                theme.widget.productList.header.title?.width,
              )}
              colStart={
                theme.widget.productList.header.horizontalAlignment ===
                HorizontalAlignment.CENTER
                  ? [1, 2, 2, 3]
                  : 1
              }
              marginTop={0}
            >
              {props.title && (
                <Title
                  id="responsive-product-list-title"
                  data-testid="product-list-page-title"
                  onBlur={() => setFocusedElement(null)}
                  ref={productListPageTitleRef}
                  hideBottomMargin={alternateResultsPosition}
                >
                  {props.facetsInTitle
                    ? `${props.facetsInTitle} ${props.title}`
                    : props.title}
                </Title>
              )}
            </StyledGridItem>
            <TotalResultsStyledGridItem
              centerAlign
              colSpan={
                theme.widget.productList.totalResults?.colspan?.alignment
              }
              colStart={
                theme.widget.productList.totalResults?.colStart?.alignment
              }
              hideOnMobile={alternateResultsPosition}
            >
              {!hideProductListTotalResults && props.totalItems > 0 && (
                <TotalResults
                  textAlign={theme.widget.productList.totalResults?.textAlign}
                >
                  {i18nText.paginationi18nText.totalResultsText}
                </TotalResults>
              )}
            </TotalResultsStyledGridItem>
            {props.description && (
              <DescriptionStyledGridItem
                colSpan={descriptionColSpan}
                colStart={
                  theme.widget.productList.header.horizontalAlignment ===
                  HorizontalAlignment.CENTER
                    ? [1, 2, 2, 3]
                    : 1
                }
                marginTop={
                  theme.widget.productList.readMoreSection.marginTopSpacing
                }
                marginBottom={
                  theme.widget.productList.readMoreSection.marginBottomSpacing
                }
              >
                {!hideProductListReadMore ? (
                  <ReadMore
                    content={props.description}
                    readMoreText={i18nText.readMore}
                    readLessText={i18nText.readLess}
                    horizontalAlignment={
                      theme.widget.productList.header.horizontalAlignment
                    }
                    numberOfLinesToShow={
                      theme.widget.productList.readMoreSection
                        .numberOfLinesToShow
                    }
                  />
                ) : (
                  <SafeHtml content={props.description} />
                )}
              </DescriptionStyledGridItem>
            )}
            {props.categories && props.categories.length > 0 && (
              <StyledGridItem
                colSpan={
                  theme.widget.productList.header.horizontalAlignment ===
                  HorizontalAlignment.CENTER
                    ? [12, 12, 10, 10]
                    : 12
                }
                colStart={
                  theme.widget.productList.header.horizontalAlignment ===
                  HorizontalAlignment.CENTER
                    ? [1, 1, 2, 2]
                    : 1
                }
                removePadding
                marginBottom={
                  theme.widget.productList.header.marginBottomSpacing
                }
              >
                <ProductCategories
                  disableHorizontalScroll={
                    theme.widget.productList.header.scrollDisabled
                  }
                  backgroundColour={theme.colors.palette.greys.white}
                  categories={props.categories}
                  title={props.categoriesTitle || undefined}
                  button={theme.widget.productList.header.button}
                  alignment={
                    theme.widget.productList.header.horizontalAlignment
                  }
                  hideCarouselButtonOnMobile={hideCarouselButtonOnMobile}
                  enableScrollableList={enableScrollableList}
                />
              </StyledGridItem>
            )}
          </ProductListIntroGrid>
          <StickyFilterGrid
            sticky={enableStickyFacetsOnMobile}
            requestIsMobileApp={requestConfig.isMobileApp}
            containerSpacing={theme.widget.productList.introSectionSpacing}
            zIndex={ZIndexLevel.Base}
            {...(!enableStickyFacetsOnDesktop && {
              forwardedRef: scrollAnchorRef,
            })}
          >
            <StyledGridItem
              colSpan={[6, 6, 3, 3]}
              colStart={1}
              marginTop={getStickyFilterMarginTop(
                theme.widget.productList.stickyFilterButtons.marginTop,
                props.categories,
                enableStickyFacetsOnMobile,
              )}
              isHorizontalFacetsEnabled={enableHorizontalFacets}
            >
              <StyledDropdown
                addEntryKey={true}
                stickyPosition
                zIndex={ZIndexLevel.Base}
                placeholder={i18nText.sortBy}
                aria-label={i18nText.sortBy}
                data-testid="sort-by-dropdown"
                options={dropdownOptions}
                useDefaultDropdownWidth
                width="100%"
                iconOverride={theme?.icons?.chevronDown}
                onChange={(event) => {
                  scrollOnChange({
                    sort: props.sortTypes.find(
                      (sort) => sort.type === event.key,
                    )?.type,
                  })

                  useGA4EnhancedEcom &&
                    pushToEventGA4<SortByDataLayerGA4>({
                      event: 'sort_by',
                      sort_method:
                        props.sortTypes.find((sort) => sort.type === event.key)
                          ?.type || '',
                    })
                }}
                selected={selectedSort}
                borderColor={
                  theme.widget.productList.filterButton.border?.color
                }
              />
            </StyledGridItem>
            <StyledGridItem
              colSpan={[12, 12, 3, 3]}
              marginTop={getStickyFilterMarginTop(
                theme.widget.productList.stickyFilterButtons.marginTop,
                props.categories,
                enableStickyFacetsOnMobile,
              )}
              hideOnMobile
              isHorizontalFacetsEnabled={enableHorizontalFacets}
            >
              <AccessibilityButton
                data-testid="go-to-refine-section-button"
                emphasis="medium"
                sizing="regular"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleGoToRefineSection()
                  }
                }}
                onClick={handleGoToRefineSection}
              >
                {i18nFacetsText.goToRefineSection}
              </AccessibilityButton>
            </StyledGridItem>
            <StyledGridItem
              colSpan={[6, 6, 6, 5]}
              colStart={enableHorizontalFacets ? [7, 7, 9, 9] : [7, 7, 7, 8]}
              marginTop={getStickyFilterMarginTop(
                theme.widget.productList.stickyFilterButtons.marginTop,
                props.categories,
                enableStickyFacetsOnMobile,
              )}
              hasReverseColumnLayout={enableHorizontalFacets}
              style={{ textAlign: 'right' }}
            >
              {
                <RefineButton
                  data-testid="mobile-facets-open-button"
                  onClick={React.useCallback(() => {
                    setShowMobileFacets(true)
                    facetsCallbacks.openMobileFacetsClicked()
                  }, [])}
                  emphasis={theme.widget.productList.filterButton.emphasis}
                >
                  <RefineIconTextWrapper>
                    <RefineText>
                      {props.pageOptions.facets.length > 0
                        ? i18nFacetsText.editRefine
                        : i18nFacetsText.refine}
                    </RefineText>
                    {theme.widget.productList.filterButton.icon.svgPath ? (
                      <SvgIcon
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={
                          theme.widget.productList.filterButton.icon.viewBox
                        }
                        width={theme.widget.productList.filterButton.icon.width}
                        height={
                          theme.widget.productList.filterButton.icon.height
                        }
                      >
                        <path
                          d={theme.widget.productList.filterButton.icon.svgPath}
                          fillRule="evenodd"
                        />
                      </SvgIcon>
                    ) : (
                      <RefineIcon />
                    )}
                  </RefineIconTextWrapper>
                </RefineButton>
              }
              {!alternateResultsPosition && showPagination && (
                <HideOnMobile>
                  <StyledPagination
                    useCustomDropdown
                    totalItems={props.totalItems}
                    itemsPerPage={props.itemsPerPage}
                    activePage={props.pageOptions.pageNumber}
                    onPageChange={(page: number) => {
                      scrollOnChange({ pageNumber: page })
                    }}
                    i18nText={{ ...i18nText.paginationi18nText }}
                    trailingSlash={false}
                    width="auto"
                    stickyPosition={true}
                  />
                </HideOnMobile>
              )}
            </StyledGridItem>
          </StickyFilterGrid>
          {enableStickyFacetsOnDesktop && <div ref={scrollAnchorRef} />}
          {enableHorizontalFacets && (
            <StyledGridItem
              zIndex={ZIndexLevel.Low}
              colSpan={12}
              hideOnMobile
              sticky={enableStickyFacetsOnDesktop}
              marginBottom={
                theme.widget.productList?.horizontalFacets
                  ?.facetGridMarginBottomSpacing || 2
              }
              marginTop={getStyledGridMarginTopSpacing(
                theme.widget.productList.horizontalFacets
                  ?.facetGridMarginTopSpacing,
                alternateResultsPosition,
              )}
              gridItemRef={
                enableStickyFacetsOnDesktop ? stickyFacetsRef : scrollAnchorRef
              }
            >
              {props.loading &&
              (props.changeType === ChangeType.FACET ||
                props.changeType === ChangeType.NONE) ? (
                <HorizontalFacetSkeleton
                  facetStyle={{
                    facet: {
                      width: '100%',
                      maxWidth: '210px',
                    },
                    sort: {
                      width: '210px',
                    },
                    container: {
                      gap: {
                        column: 4,
                        row: 2,
                      },
                    },
                  }}
                />
              ) : (
                <HorizontalFacets
                  stickyOnDesktop={enableStickyFacetsOnDesktop}
                  sortTypes={props.sortTypes}
                  dropDownOptions={dropdownOptions}
                  facets={props.facets}
                  selectedFacets={props.pageOptions.facets}
                  onInputChange={scrollOnChange}
                  i18nText={{ ...i18nFacetsText, sortBy: i18nText.sortBy }}
                  selectedSort={selectedSort}
                  hideMargin={alternateResultsPosition}
                  facetStyle={
                    theme.widget.productList.horizontalFacets || {
                      facet: {
                        width: '100%',
                        maxWidth: '210px',
                        numberAlignment: HorizontalAlignment.RIGHT,
                      },
                      sort: {
                        width: '210px',
                      },
                      container: {
                        gap: {
                          column: 4,
                          row: 2,
                        },
                      },
                    }
                  }
                  headerHeight={headerHeight}
                  facetSchema={facetSchema}
                />
              )}
            </StyledGridItem>
          )}
          <ProductListGrid>
            {enableHorizontalFacets && alternateResultsPosition && (
              <TotalResultsStyledGridItem
                centerAlign
                colSpan={[6, 6, 12, 12]}
                colStart={1}
                alternateResultsPosition={alternateResultsPosition}
              >
                {!hideProductListTotalResults && props.totalItems > 0 && (
                  <TotalResults
                    alternateResultsPosition={alternateResultsPosition}
                  >
                    {i18nText.paginationi18nText.totalResultsText}
                  </TotalResults>
                )}
              </TotalResultsStyledGridItem>
            )}
            <StyledGridItem
              colSpan={[6, 6, 6, 5]}
              colStart={enableHorizontalFacets ? [7, 7, 9, 9] : [7, 7, 7, 8]}
              hasReverseColumnLayout={enableHorizontalFacets}
              style={{
                textAlign: 'right',
              }}
            >
              {enableProductListGridSelector && (
                <ProductListGridSelector
                  productViewColumn={
                    productViewColumn === GridView.SingleColumn
                  }
                  setProductViewColumn={setProductViewColumn}
                  setSingleColumn={(event, value) => {
                    props.setSingleColumn && props.setSingleColumn(event, value)
                    if (useGA4EnhancedEcom) {
                      pushToEventGA4<ColumnTypeDataLayerGA4>({
                        event: 'column_type_selected',
                        col_type: 'Updated',
                        col_number: GridSelectorType[value],
                      })
                    }
                  }}
                />
              )}
            </StyledGridItem>
          </ProductListGrid>
          <ProductListGrid>
            <ProductListGridItem
              colSpan={12}
              marginTop={
                enableProductListGridSelector ||
                (enableHorizontalFacets && alternateResultsPosition)
                  ? 1
                  : 3
              }
              containerSpacing={theme.widget.productList.containerSpacing}
            >
              <HiddenTitle>{i18nText.productsHiddenTitle}</HiddenTitle>
              <ProductListRenderer
                path={props.path}
                sponsoredAdsType={props.sponsoredAdsType}
                searchData={{
                  itemsPerPage: props.itemsPerPage,
                  searchQuery: props.pageOptions.searchQuery,
                  sortOrder: props.pageOptions.sort,
                  facets: props.pageOptions.facets,
                  pageNumber: props.pageOptions.pageNumber,
                }}
                productBlocks={productBlocks}
                isHorizontalFacetsEnabled={enableHorizontalFacets}
                oneProductPerRow={productViewColumn === GridView.SingleColumn}
                fourProductsPerRowDesktop={hasFourProductsPerRowDesktopPLP}
              />
            </ProductListGridItem>
            {showPagination && (
              <StyledGridItem colSpan={[12, 12, 6, 5]} colStart={[1, 1, 7, 8]}>
                <StyledPagination
                  useCustomDropdown
                  totalItems={props.totalItems}
                  itemsPerPage={props.itemsPerPage}
                  activePage={props.pageOptions.pageNumber}
                  onPageChange={(page: number) => {
                    scrollOnChange({ pageNumber: page })
                    handleGoToProductSection()
                  }}
                  i18nText={{ ...i18nText.paginationi18nText }}
                  trailingSlash={false}
                  removeBlurFocus
                  width="auto"
                  stickyPosition={true}
                />
              </StyledGridItem>
            )}
            {props.seoDescription && (
              <ProductListFooterGrid
                colSpan={[12, 12, 12, 12]}
                containerSpacing={theme.widget.productList.readMoreSpacing}
              >
                {!hideProductListFooterReadMore ? (
                  <ReadMore
                    content={props.seoDescription}
                    readMoreText={i18nText.readMore}
                    readLessText={i18nText.readLess}
                    numberOfLinesToShow={
                      theme.widget.productList.readMoreSection
                        .numberOfLinesToShow
                    }
                  />
                ) : (
                  <StyledSafeHTML content={props.seoDescription} />
                )}
              </ProductListFooterGrid>
            )}
          </ProductListGrid>
        </div>
      </FacetProductWrapper>
    </Container>
  )
}
