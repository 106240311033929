import * as React from 'react'
import loadable from '@loadable/component'

import { css, spacing, styled, Text } from '@thg-commerce/gravity-theme'

const ChevronLeft = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronLeft'),
  {
    ssr: true,
    fallback: (
      <div style={{ width: 24, height: 24, marginRight: spacing(1) }} />
    ),
  },
)
const ChevronRight = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronRight'),
  {
    ssr: true,
    fallback: <div style={{ width: 24, height: 24, marginLeft: spacing(1) }} />,
  },
)

import { Button } from '../Button'
import { Dropdown } from '../FormItem'

const getAlignment = (align?: 'left' | 'center' | 'right') => {
  if (!align) return 'flex-end'

  switch (align) {
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
  }
}

export const PagniationContainer = styled.div<{
  align?: 'left' | 'center' | 'right'
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => getAlignment(props?.align)};
`

export const PaginationContent = styled.nav`
  display: flex;
  align-items: center;
`

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledDropdown = styled(Dropdown)`
  min-width: 60px;
`

export const StyledLabel = styled.label`
  min-width: 64px;
`

export const Total = styled.span`
  white-space: nowrap;
  margin-left: ${spacing(1)};
`

export const SvgStyles = css`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.colors.palette.greys.darker};
`

export const StyledChevronLeft = styled(ChevronLeft)`
  ${SvgStyles}

  margin-right: ${spacing(1)};
`

export const StyledChevronRight = styled(ChevronRight)`
  ${SvgStyles}

  margin-left: ${spacing(1)};
`

export const NavigationButton = styled(Button)`
  ${Text('bodyText', 'alternate')}
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${spacing(4)};
  text-decoration: none;
  color: ${(props) => props.theme.colors.palette.greys.darker};
  padding: ${spacing(0.5)};
  border: 1px solid transparent;

  &:focus-visible {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.palette.greys.darker};
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:active {
    color: ${(props) => props.theme.colors.palette.greys.dark};
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${(props) => props.theme.colors.palette.greys.light};
      cursor: not-allowed;
      pointer-events: none;

      svg > * {
        fill: ${(props) => props.theme.colors.palette.greys.light};
      }
    `}
`
