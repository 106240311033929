import { spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Button } from '@thg-commerce/gravity-elements'
import { MarginValue } from '@thg-commerce/gravity-theme/margin'

import { AccessibilityButton } from '../../styles'

export const Title = styled.h2`
  ${(props) =>
    Text(
      props.theme.widget.productList.verticalFacets?.heading?.textStyle.entry ||
        'medium1',
      props.theme.widget.productList.verticalFacets?.heading?.textStyle.style ||
        'alternate',
    )};
`

export const ClearAllButton = styled(Button)`
  margin-top: ${spacing(1)};
`

export const FacetsContainer = styled.div`
  margin-top: ${(props) =>
    props.theme.widget.productList.verticalFacets?.container?.margin.top ||
    props.theme.widget.productList.verticalFacets?.container?.margin.top === 0
      ? props.theme.widget.productList.verticalFacets?.container?.margin.top
      : spacing(3)};
`

export const AccordionContainer = styled.div<{ marginTop?: MarginValue }>`
  &:not(:first-child) {
    margin-top: ${(props) =>
      props.marginTop || props.marginTop === 0 ? props.marginTop : spacing(4)};
  }
`

export const StyledAccessibilityButton = styled(AccessibilityButton)`
  margin-top: ${spacing(2)};
`
