import * as React from 'react'
import handleViewport from 'react-in-viewport'

import { useQubitPlacementImpression } from '@thg-commerce/enterprise-components/Qubit/useQubitPlacementImpression'
import {
  i18n,
  useFormattableI18nProperty,
  useSiteConfig,
  useTheme,
  withCacheConfiguration,
} from '@thg-commerce/enterprise-core'
import { ESIComponent, withESIWrapper } from '@thg-commerce/enterprise-esi'
import { ESIRequestContext } from '@thg-commerce/enterprise-esi/src/types'
import {
  Beacon,
  HmacAppliedProductWithBeacon,
} from '@thg-commerce/enterprise-network/src/transformers/sponsoredAds/products'
import { ProductItem } from '@thg-commerce/enterprise-widget-product-list-page'
import { ProductListItem } from '@thg-commerce/enterprise-widget-product-list-page/src/styles'

import {
  SearchData,
  SponsoredAdsQueryReturnType,
  sponsoredAdsQueryTypes,
  SponsoredAdsType,
  unwrapSponsoredAdsResponse,
} from './queryBuilders'

export interface SponsoredAdsProps {
  sponsoredAdsType: SponsoredAdsType
  path?: string
  searchData?: SearchData
  productSku?: number
}

export interface SponsoredAdsInitialProps {
  sponsoredAdsData?: HmacAppliedProductWithBeacon[]
  sponsoredAdsBeacons?: {
    onLoadBeacon?: Beacon | null
    onViewBeacon?: Beacon | null
    onClickBeacon?: Beacon | null
  }
}

const SponsoredAds: ESIComponent<
  SponsoredAdsProps & SponsoredAdsInitialProps,
  SponsoredAdsInitialProps
> = (props) => {
  const theme = useTheme()
  const {
    enableHorizontalFacets,
    hideProductListReviewRating,
    hideProductListProductBlockButton,
    productListReviewRatingThreshold,
    showMarketedSpecialOfferIcon,
    hideProductListImageRollover,
    showProductListProductBlockIcons,
    enableClickAndCollect,
    showPdpLinkWhenOutOfStock,
    showProductBlockBrandTitle,
    hideProductListSwatch,
    previewQuickbuy,
  } = useSiteConfig()
  const emitImpressionEvent = useQubitPlacementImpression()

  const productI18nText = {
    buyAriaLabel: useFormattableI18nProperty('general.productbuynow.label'),
    buyText: i18n('general.productbuynow.text'),
    quickBuyText: i18n('general.product.quickbuy.text'),
    soldOutText: i18n('general.productsoldout.text'),
    reviewsStarsLabel: i18n('reviews.stars.label'),
    reviewsLabel: i18n('reviews.reviews.label'),
    closeI18nText: {
      closeAriaLabel: i18n('general.modal.close.button.arialabel'),
      closeLabel: i18n('general.modal.close.button.label'),
    },
    freeGiftMessage: i18n('product.marketedspecialoffer.freegift.text'),
    swatchUnavailableText: i18n(
      'product.productoptions.swatch.tooltip.unavailable.text',
    ),
    swatchCloseButtonText: i18n(
      'product.productoptions.swatch.tooltip.close.text',
    ),
    fulfilmentMethodsText: {
      clickAndCollect: {
        isAvailable: i18n('account.orderdetail.clickandcollect.text'),
        isNotAvailable: i18n('product.clickandcollect.unavailable.text'),
      },
      homeDelivery: {
        isAvailable: i18n('product.delivery.home.instock.text'),
        isNotAvailable: i18n('product.delivery.home.unavailable.text'),
        isOutOfStock: i18n('product.delivery.home.outofstock.text'),
      },
      storeDelivery: {
        isAvailable: i18n('product.delivery.store.available.text'),
      },
    },
    sponsoredLabelText: i18n('sponsored.label.text'),
  }

  const wishlistI18nText = {
    addToWishlistTextDynamic: useFormattableI18nProperty(
      'product.addtowishlistbutton.addtowishlist.text.dynamic',
    ),
    addToWishlistText: i18n('product.addtowishlistbutton.addtowishlist.text'),
    savedToWishlistText: i18n(
      'product.addtowishlistbutton.savedtowishlist.text',
    ),
    wishlistTooltip: {
      closeButtonText: i18n('product.addtowishlist.tooltip.button.close.text'),
    },
    wishlistTooltipContent: {
      loginSignupText: i18n('product.addtowishlist.tooltip.loginsignup.text'),
      toUseWishlistText: i18n('product.addtowishlist.tooltip.text'),
    },
  }

  React.useEffect(() => {
    if (props.sponsoredAdsBeacons?.onLoadBeacon) {
      navigator.sendBeacon(props.sponsoredAdsBeacons?.onLoadBeacon.url)
    }

    props.sponsoredAdsData?.forEach(
      (sponsoredAd: HmacAppliedProductWithBeacon) => {
        if (sponsoredAd?.onLoadBeacon) {
          navigator.sendBeacon(sponsoredAd?.onLoadBeacon.url)
        }
      },
    )
  }, [])

  if (!props.sponsoredAdsData || props.sponsoredAdsData.length < 1) {
    return null
  }

  const ProductItemViewport = handleViewport(ProductItem)

  const sponsoredProductBlocks = props.sponsoredAdsData.map(
    (sponsoredAd, index: number) => {
      if (!sponsoredAd?.product) {
        return
      }

      const sponsoredAdsBeacons = {
        topLevel: props.sponsoredAdsBeacons?.onViewBeacon,
        productLevel: {
          onViewBeacon: sponsoredAd.onViewBeacon,
          onWishlistBeacon: sponsoredAd.onWishlistBeacon,
          onBasketChangeBeacon: sponsoredAd.onBasketChangeBeacon,
          onClickBeacon: sponsoredAd.onClickBeacon,
        },
      }

      return (
        <ProductListItem data-testid="product-item">
          <ProductItemViewport
            wrapperProps={{
              style: { height: '100%' },
            }}
            attributesInsetSpacing={
              theme.widget.productList.attributesInsetSpacing
            }
            emitImpressionEvent={emitImpressionEvent}
            style={{ width: '100%' }}
            key={index}
            index={index}
            quickBuyFeatureEnabled={previewQuickbuy}
            productI18nText={productI18nText}
            wishlistI18nText={wishlistI18nText}
            lazy={false}
            siteConfig={{
              hideProductListReviewRating,
              hideProductListProductBlockButton,
              productListReviewRatingThreshold,
              showMarketedSpecialOfferIcon,
              hideProductListImageRollover,
              showProductListProductBlockIcons,
              enableClickAndCollect,
              showPdpLinkWhenOutOfStock,
              enableHorizontalFacets,
              showProductBlockBrandTitle,
              hideProductListSwatch,
            }}
            {...sponsoredAd?.product}
            wishlistButtonStyle={theme.widget.productList.wishlistButton?.style}
            qubitBadgeStyle={theme.widget.productList.qubitBadge}
            isSponsored={true}
            sponsoredAdsBeacons={sponsoredAdsBeacons}
          />
        </ProductListItem>
      )
    },
  )

  return <React.Fragment>{sponsoredProductBlocks}</React.Fragment>
}

SponsoredAds.getInitialProps = withCacheConfiguration<
  SponsoredAdsInitialProps,
  ESIRequestContext<SponsoredAdsInitialProps & SponsoredAdsProps>
>(async (context) => {
  const queryBuilder = sponsoredAdsQueryTypes[context.props.sponsoredAdsType]
  const query = queryBuilder(context)

  if (!query) {
    return {
      ...context.props,
      sponsoredAdsData: [],
      sponsoredAdsBeacons: {},
    }
  }

  try {
    const { data } = await context.apolloClient.query<
      SponsoredAdsQueryReturnType
    >({
      query: query.options.queryString,
      variables: query.options.queryVariables,
    })

    if (!data) {
      return {
        ...context.props,
        sponsoredAdsData: [],
        sponsoredAdsBeacons: {},
      }
    }

    const sponsoredAds = unwrapSponsoredAdsResponse(data)

    if (!sponsoredAds) {
      return {
        ...context.props,
        sponsoredAdsData: [],
        sponsoredAdsBeacons: {},
      }
    }

    const sponsoredProducts =
      sponsoredAds?.placementFormatToProducts?.[0]?.value
    const topLevelBeacons = {
      onLoadBeacon: sponsoredAds?.onLoadBeacon,
      onViewBeacon: sponsoredAds?.onViewBeacon,
      onClickBeacon: sponsoredAds?.onClickBeacon,
    }

    return {
      ...context.props,
      sponsoredAdsData: sponsoredProducts ?? [],
      sponsoredAdsBeacons: topLevelBeacons,
      cache: {
        ttl: 0,
      },
    }
  } catch (error) {
    console.warn(`Sponsored ads Terra error: ${error}`)
    return {}
  }
})

const ESISponsoredAds = withESIWrapper(SponsoredAds)

export { ESISponsoredAds as SponsoredAds }

export default ESISponsoredAds
